<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="alert">
            <path fill="currentColor" opacity=".4" d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z"/>
            <path fill="currentColor" d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"/>
        </g>
        <g id="link">
            <path fill="currentColor" d="M44.45 252.59l37.11-37.1c9.84-9.84 26.78-3.3 27.29 10.6a184.45 184.45 0 0 0 9.69 52.72 16.08 16.08 0 0 1-3.78 16.61l-13.09 13.09c-28 28-28.9 73.66-1.15 102a72.07 72.07 0 0 0 102.32.51L270 343.79A72 72 0 0 0 270 242a75.64 75.64 0 0 0-10.34-8.57 16 16 0 0 1-6.95-12.6A39.86 39.86 0 0 1 264.45 191l21.06-21a16.06 16.06 0 0 1 20.58-1.74A152.05 152.05 0 0 1 327 400l-.36.37-67.2 67.2c-59.27 59.27-155.7 59.26-215 0s-59.26-155.72.01-214.98z"/>
            <path fill="currentColor" opacity=".4" d="M410.33 203.49c28-28 28.9-73.66 1.15-102a72.07 72.07 0 0 0-102.32-.49L242 168.21A72 72 0 0 0 242 270a75.64 75.64 0 0 0 10.34 8.57 16 16 0 0 1 6.94 12.6A39.81 39.81 0 0 1 247.55 321l-21.06 21.05a16.07 16.07 0 0 1-20.58 1.74A152.05 152.05 0 0 1 185 112l.36-.37 67.2-67.2c59.27-59.27 155.7-59.26 215 0s59.27 155.7 0 215l-37.11 37.1c-9.84 9.84-26.78 3.3-27.29-10.6a184.45 184.45 0 0 0-9.69-52.72 16.08 16.08 0 0 1 3.78-16.61z"/>
        </g>
        <g id="power">
            <path fill="currentColor" d="M15.7143 4.48713e-06C15.902 -0.000466618 16.088 0.0361629 16.2615 0.107788C16.4351 0.179414 16.5927 0.284623 16.7255 0.417372C16.8582 0.550121 16.9634 0.707792 17.0351 0.881327C17.1067 1.05486 17.1433 1.24084 17.1429 1.42857V15.7143C17.1433 15.902 17.1067 16.088 17.0351 16.2615C16.9634 16.435 16.8582 16.5927 16.7255 16.7255C16.5927 16.8582 16.4351 16.9634 16.2615 17.0351C16.088 17.1067 15.902 17.1433 15.7143 17.1428H13.8095C13.6218 17.1433 13.4358 17.1067 13.2623 17.0351C13.0887 16.9634 12.9311 16.8582 12.7983 16.7255C12.6656 16.5927 12.5604 16.435 12.4887 16.2615C12.4171 16.088 12.3805 15.902 12.381 15.7143V1.42857C12.3805 1.24084 12.4171 1.05486 12.4887 0.881327C12.5604 0.707792 12.6656 0.550121 12.7983 0.417372C12.9311 0.284623 13.0887 0.179414 13.2623 0.107788C13.4358 0.0361629 13.6218 -0.000466618 13.8095 4.48713e-06H15.7143Z"/>
            <path fill="currentColor" opacity=".4" d="M29.5238 15.2381C29.5238 23.3809 22.9286 29.9821 14.7917 30C6.66668 30.0178 0.0119271 23.3928 2.2364e-05 15.2619C-0.00407376 12.9085 0.554562 10.5882 1.62934 8.49457C2.70412 6.40092 4.2639 4.59459 6.17858 3.2262C6.34201 3.10875 6.52834 3.02705 6.72544 2.98642C6.92255 2.94579 7.126 2.94714 7.32255 2.99038C7.51909 3.03362 7.70433 3.11778 7.86618 3.23738C8.02803 3.35699 8.16287 3.50934 8.26192 3.68453L9.20239 5.35715C9.37233 5.65993 9.42619 6.01424 9.35389 6.35384C9.28158 6.69344 9.08807 6.99509 8.80953 7.20238C7.11886 8.45467 5.86623 10.2083 5.22992 12.2137C4.59361 14.2192 4.60604 16.3742 5.26543 18.3721C5.92482 20.3701 7.19758 22.1092 8.90258 23.3419C10.6076 24.5746 12.658 25.2381 14.7619 25.2381C20.2143 25.2381 24.7976 20.8214 24.7619 15.1726C24.7516 13.6197 24.38 12.0905 23.6765 10.7061C22.9729 9.32171 21.9567 8.1201 20.7083 7.19643C20.4308 6.98964 20.2383 6.68863 20.1671 6.34995C20.0959 6.01126 20.1507 5.65822 20.3214 5.35715L21.2619 3.68453C21.3601 3.51013 21.4938 3.35831 21.6544 3.2389C21.8151 3.1195 21.999 3.03518 22.1943 2.9914C22.3896 2.94763 22.5919 2.94538 22.7881 2.9848C22.9843 3.02423 23.1701 3.10444 23.3333 3.22025C25.2495 4.58388 26.8114 6.38645 27.8883 8.47723C28.9653 10.568 29.5261 12.8862 29.5238 15.2381V15.2381Z" />
        </g>
        <g id="plus">
            <path fill="currentColor" d="M176 448a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32V304h-96zm64-416h-32a32 32 0 0 0-32 32v144h96V64a32 32 0 0 0-32-32z"/>
            <path fill="currentColor" d="M448 240v32a32 32 0 0 1-32 32H32a32 32 0 0 1-32-32v-32a32 32 0 0 1 32-32h384a32 32 0 0 1 32 32z"/>
        </g>
        <g id="minus">
            <path fill="currentColor" d="M448 240v32a32 32 0 0 1-32 32H32a32 32 0 0 1-32-32v-32a32 32 0 0 1 32-32h384a32 32 0 0 1 32 32z"/>
        </g>
        <g id="in_air">
            <path fill="currentColor" opacity=".4" d="M40 3.70371L40 40.7408C40 41.723 39.6049 42.6651 38.9017 43.3597C38.1984 44.0542 37.2446 44.4445 36.25 44.4445L28.8125 44.4445C27.9581 44.4452 27.1298 44.1534 26.4688 43.6188L17.25 36.3349C17.1454 36.2519 17.0601 36.1477 16.9998 36.0294C16.9395 35.911 16.9056 35.7813 16.9004 35.6489C16.8952 35.5165 16.9189 35.3846 16.9698 35.262C17.0206 35.1394 17.0976 35.029 17.1953 34.9383L19.5 32.8087C19.6647 32.6566 19.8793 32.5682 20.1045 32.5597C20.3298 32.5512 20.5506 32.6231 20.7266 32.7624L25 36.142L25 29.0124L30 26.5432L30 17.9012L25 15.4321L25 8.31019L20.6719 11.7284C20.4919 11.8673 20.2673 11.9376 20.0391 11.9262C19.811 11.9149 19.5946 11.8227 19.4297 11.6667L17.1719 9.49846C17.0777 9.406 17.0043 9.29502 16.9564 9.17272C16.9085 9.05041 16.8871 8.91952 16.8938 8.78855C16.9004 8.65757 16.9348 8.52944 16.9949 8.41246C17.0549 8.29549 17.1392 8.19229 17.2422 8.10957L26.4766 0.810191C27.1392 0.285481 27.9632 -0.000314848 28.8125 5.20101e-06L36.25 4.87591e-06C37.2446 4.83243e-06 38.1984 0.390217 38.9017 1.0848C39.6049 1.77938 40 2.72143 40 3.70371Z"/>
            <path fill="currentColor" d="M12.5 31.2114L12.5 26.4352L1.33594 26.4352C0.982005 26.434 0.642921 26.2946 0.392652 26.0474C0.142382 25.8002 0.00123529 25.4653 -1.05332e-07 25.1158L-3.36705e-07 19.8225C-0.000103046 19.6492 0.0343819 19.4776 0.101483 19.3175C0.168583 19.1574 0.266984 19.0119 0.391058 18.8893C0.515131 18.7668 0.662445 18.6696 0.824574 18.6033C0.986704 18.5371 1.16047 18.503 1.33594 18.5031L12.5 18.5031L12.5 13.233C12.5 11.8596 14.1797 11.1729 15.1641 12.1451L24.5391 21.358C24.6864 21.5033 24.8028 21.6762 24.8815 21.8665C24.9602 22.0568 24.9997 22.2607 24.9975 22.4663C24.9953 22.6719 24.9516 22.875 24.8688 23.0636C24.7861 23.2522 24.666 23.4227 24.5156 23.5648L15.1406 32.3303C14.1484 33.2639 12.5 32.5695 12.5 31.2114Z"/>
        </g>
        <g id="out_air">
            <path fill="currentColor" opacity=".4" d="M13.5266 0.81251C12.8614 0.286787 12.0385 0.00055025 11.1906 8.06186e-06L3.75237 7.73672e-06C2.7578 7.69325e-06 1.80398 0.395098 1.10071 1.09836C0.397453 1.80162 0.00236493 2.75545 0.00236489 3.75001L0.00236325 41.25C0.00236321 42.2446 0.397451 43.1984 1.10071 43.9016C1.80397 44.6049 2.7578 45 3.75236 45L11.1899 45C12.0416 45.0004 12.868 44.7111 13.5336 44.1797L22.7524 36.8047C22.8569 36.7207 22.9423 36.6152 23.0026 36.4954C23.0629 36.3755 23.0968 36.2442 23.1019 36.1101C23.1071 35.9761 23.0835 35.8425 23.0326 35.7184C22.9817 35.5942 22.9048 35.4825 22.8071 35.3906L20.5024 33.2344C20.3377 33.0804 20.1231 32.9909 19.8978 32.9823C19.6726 32.9737 19.4517 33.0465 19.2758 33.1875L15.0024 36.5938L15.0024 29.375L10.0024 26.875L10.0024 18.125L15.0024 15.625L15.0024 8.40626L19.3305 11.8672C19.509 12.0114 19.7344 12.0847 19.9635 12.0732C20.1927 12.0617 20.4096 11.9661 20.5727 11.8047L22.8305 9.60938C22.926 9.51651 23.0007 9.40435 23.0494 9.28036C23.0982 9.15638 23.12 9.02342 23.1133 8.89036C23.1067 8.75729 23.0717 8.62717 23.0109 8.50867C22.95 8.39017 22.8645 8.28601 22.7602 8.20313L13.5266 0.81251Z"/>
            <path fill="currentColor" d="M16.3391 26.2734L27.5024 26.2734L27.5024 31.6094C27.5024 33 29.1821 33.6953 30.1664 32.7109L39.5414 23.3828C39.6887 23.2357 39.8052 23.0607 39.8839 22.868C39.9626 22.6753 40.002 22.4688 39.9999 22.2607C39.9977 22.0526 39.9539 21.8469 39.8712 21.6559C39.7884 21.4649 39.6683 21.2924 39.518 21.1484L30.143 12.2656C29.1508 11.3203 27.5024 12.0234 27.5024 13.3984L27.5024 18.2344L16.3383 18.2344C15.9831 18.236 15.6431 18.3784 15.3927 18.6303C15.1423 18.8821 15.0019 19.223 15.0024 19.5781L15.0024 24.9375C15.0023 25.113 15.0368 25.2869 15.1039 25.449C15.1711 25.6112 15.2695 25.7586 15.3937 25.8827C15.5179 26.0067 15.6653 26.1051 15.8275 26.1722C15.9897 26.2392 16.1635 26.2736 16.3391 26.2734V26.2734Z"/>
        </g>
        <g id="fan_low">
            <path fill="currentColor" opacity=".4" d="M37.9304 23.3953C36.3287 19.3761 31.0752 18.1358 27.5155 17.7632C27.9282 18.636 28.1634 19.584 28.2072 20.5507C28.251 21.5175 28.1024 22.4833 27.7703 23.3907C27.4382 24.2981 26.9294 25.1286 26.2741 25.8329C25.6188 26.5371 24.8304 27.1007 23.9558 27.4901C24.7929 32.0088 20.5028 40.4869 26.3738 40.8658C33.7014 41.339 40.4282 29.6592 37.9304 23.3953Z"/>
            <path fill="currentColor" opacity=".4" d="M13.9718 20.882C13.9718 20.5902 13.9891 20.2987 14.0236 20.0089C9.71591 18.6038 4.54212 10.387 1.24971 15.3989C-2.82177 21.5904 3.80035 33.3353 10.4049 34.2766C14.5934 34.8737 18.2443 30.9836 20.3505 28.0568C18.5999 27.8708 16.9793 27.0347 15.8014 25.7098C14.6234 24.3849 13.9717 22.665 13.9718 20.882V20.882Z"/>
            <path fill="currentColor" opacity=".4" d="M35.5828 6.09702C32.3204 -0.571918 18.9726 -0.632784 14.8628 4.68977C12.2106 8.12656 13.8143 13.3998 15.2844 16.7065C15.827 15.9329 16.5149 15.2753 17.3087 14.7715C18.1026 14.2676 18.9868 13.9274 19.9107 13.7702C20.8346 13.6131 21.78 13.6421 22.6928 13.8557C23.6056 14.0693 24.4678 14.4632 25.23 15.0148C28.5722 11.9233 38.2256 11.5014 35.5828 6.09702Z"/>
            <path fill="currentColor" opacity=".4" d="M21.0922 15.0159C19.9466 15.0159 18.8267 15.36 17.8741 16.0047C16.9216 16.6495 16.1791 17.5659 15.7408 18.638C15.3024 19.7102 15.1878 20.8899 15.4113 22.0281C15.6349 23.1662 16.1867 24.2117 16.9968 25.0322C17.807 25.8527 18.8392 26.4114 19.9629 26.6377C21.0865 26.8639 22.2512 26.7476 23.3096 26.3034C24.368 25.8591 25.2725 25.1069 25.9089 24.1419C26.5452 23.177 26.8847 22.0425 26.8845 20.882C26.8845 20.1116 26.7347 19.3487 26.4436 18.6369C26.1525 17.9251 25.7258 17.2784 25.1879 16.7337C24.6501 16.1889 24.0115 15.7568 23.3088 15.4621C22.606 15.1674 21.8528 15.0157 21.0922 15.0159V15.0159ZM21.0922 25.4049C20.2081 25.4049 19.3438 25.1394 18.6087 24.6418C17.8735 24.1442 17.3005 23.437 16.9622 22.6096C16.6238 21.7822 16.5353 20.8717 16.7078 19.9934C16.8803 19.115 17.306 18.3082 17.9312 17.6749C18.5564 17.0416 19.353 16.6103 20.2201 16.4356C21.0873 16.2609 21.9861 16.3506 22.803 16.6933C23.6198 17.036 24.318 17.6164 24.8092 18.3611C25.3004 19.1057 25.5626 19.9812 25.5626 20.8768C25.5634 21.4719 25.4484 22.0613 25.2241 22.6114C24.9998 23.1614 24.6706 23.6613 24.2554 24.0824C23.8402 24.5034 23.3471 24.8374 22.8043 25.0653C22.2616 25.2931 21.6798 25.4103 21.0922 25.4102V25.4049Z"/>
            <path fill="currentColor" d="M21.0933 23.3954C22.4636 23.3954 23.5745 22.2701 23.5745 20.882C23.5745 19.494 22.4636 18.3687 21.0933 18.3687C19.7229 18.3687 18.6121 19.494 18.6121 20.882C18.6121 22.2701 19.7229 23.3954 21.0933 23.3954Z"/>
        </g>
        <g id="fan_high">
            <path fill="currentColor" opacity=".4" d="M43.0079 39.6187C41.307 41.7233 39.2464 43.5097 36.9218 44.8949C39.4356 42.8072 41.5194 39.9359 42.6392 37.0363C44.8548 33.7713 46.1104 29.9505 46.2636 26.0077C46.4167 22.0649 45.4612 18.1582 43.5055 14.7312C44.0013 13.9699 44.1305 12.9929 43.7302 11.6988C46.7009 15.7827 48.2405 20.733 48.1098 25.7814C47.9792 30.8298 46.1858 35.6939 43.0079 39.6187Z"/>
            <path fill="currentColor" opacity=".4" d="M7.38403 10.7665C14.3661 2.14442 26.31 -0.0856103 35.7859 4.85739C32.749 3.8654 29.3371 3.59958 26.3952 4.14061C23.057 3.94842 19.721 4.554 16.6633 5.90727C13.6056 7.26054 10.9142 9.32257 8.81174 11.9227C8.15976 12.7302 7.56719 13.5839 7.03867 14.4772C6.13288 14.4328 5.22073 14.814 4.30122 15.8131L4.25767 15.8613C5.0732 14.034 6.12423 12.3212 7.38403 10.7665V10.7665Z"/>
            <path fill="currentColor" opacity=".4" d="M2.27576 25.6903C2.86043 28.7984 4.26454 31.9001 6.14338 34.2415C7.85042 37.8298 10.5359 40.8632 13.8907 42.9927C17.2455 45.1222 21.1334 46.2612 25.107 46.2788C25.5336 47.0403 26.3084 47.6058 27.5832 47.8881C27.6892 47.9118 27.7935 47.932 27.899 47.9521C24.7246 48.3293 21.5064 48.0384 18.4511 47.0981C15.3958 46.1578 12.5709 44.5889 10.1579 42.4921C7.74488 40.3953 5.79707 37.8171 4.43957 34.9229C3.08207 32.0287 2.3449 28.8826 2.27537 25.6866L2.27576 25.6903Z"/>
            <path fill="currentColor" opacity=".4" d="M41.9304 27.3951C40.3287 23.3759 35.0752 22.1355 31.5155 21.7629C31.9282 22.6358 32.1634 23.5837 32.2072 24.5505C32.251 25.5172 32.1024 26.483 31.7703 27.3904C31.4382 28.2978 30.9294 29.1283 30.2741 29.8326C29.6188 30.5368 28.8304 31.1004 27.9558 31.4898C28.7929 36.0086 24.5028 44.4867 30.3738 44.8655C37.7014 45.3388 44.4282 33.6589 41.9304 27.3951Z"/>
            <path fill="currentColor" opacity=".4" d="M17.9718 24.8818C17.9718 24.5899 17.9891 24.2984 18.0236 24.0087C13.7159 22.6035 8.54212 14.3867 5.24971 19.3986C1.17823 25.5901 7.80035 37.335 14.4049 38.2763C18.5934 38.8734 22.2443 34.9833 24.3505 32.0565C22.5999 31.8705 20.9793 31.0344 19.8014 29.7095C18.6234 28.3846 17.9717 26.6647 17.9718 24.8818V24.8818Z"/>
            <path fill="currentColor" opacity=".4" d="M39.5828 10.0967C36.3204 3.42781 22.9726 3.36694 18.8628 8.6895C16.2106 12.1263 17.8143 17.3995 19.2844 20.7062C19.827 19.9326 20.5149 19.275 21.3088 18.7712C22.1026 18.2673 22.9868 17.9271 23.9107 17.7699C24.8346 17.6128 25.78 17.6418 26.6928 17.8554C27.6056 18.069 28.4678 18.4629 29.23 19.0145C32.5722 15.923 42.2256 15.5012 39.5828 10.0967Z"/>
            <path fill="currentColor" opacity=".4" d="M25.0922 19.0156C23.9466 19.0156 22.8267 19.3597 21.8741 20.0045C20.9216 20.6492 20.1791 21.5656 19.7408 22.6378C19.3024 23.7099 19.1878 24.8897 19.4113 26.0278C19.6349 27.166 20.1867 28.2114 20.9968 29.0319C21.807 29.8524 22.8392 30.4111 23.9629 30.6374C25.0865 30.8637 26.2512 30.7473 27.3096 30.3031C28.368 29.8588 29.2725 29.1067 29.9089 28.1417C30.5452 27.1767 30.8847 26.0422 30.8845 24.8817C30.8845 24.1113 30.7347 23.3484 30.4436 22.6366C30.1525 21.9248 29.7258 21.2781 29.1879 20.7334C28.6501 20.1886 28.0115 19.7566 27.3088 19.4618C26.606 19.1671 25.8528 19.0155 25.0922 19.0156V19.0156ZM25.0922 29.4047C24.2081 29.4047 23.3438 29.1391 22.6087 28.6415C21.8735 28.144 21.3005 27.4368 20.9622 26.6093C20.6238 25.7819 20.5353 24.8715 20.7078 23.9931C20.8803 23.1147 21.306 22.3079 21.9312 21.6746C22.5564 21.0413 23.353 20.6101 24.2201 20.4353C25.0873 20.2606 25.9861 20.3503 26.803 20.693C27.6198 21.0357 28.318 21.6161 28.8092 22.3608C29.3004 23.1054 29.5626 23.9809 29.5626 24.8765C29.5634 25.4716 29.4484 26.0611 29.2241 26.6111C28.9998 27.1612 28.6706 27.661 28.2554 28.0821C27.8402 28.5032 27.3471 28.8372 26.8043 29.065C26.2616 29.2928 25.6798 29.41 25.0922 29.4099V29.4047Z"/>
            <path fill="currentColor" d="M25.0933 27.3951C26.4636 27.3951 27.5745 26.2698 27.5745 24.8818C27.5745 23.4937 26.4636 22.3684 25.0933 22.3684C23.7229 22.3684 22.6121 23.4937 22.6121 24.8818C22.6121 26.2698 23.7229 27.3951 25.0933 27.3951Z"/>
        </g>
        <g id="temp">
            <path fill="currentColor" opacity=".4" d="M17.85 17.9502V7.21875C17.85 5.30422 17.0756 3.4681 15.6972 2.11432C14.3188 0.760545 12.4493 0 10.5 0C8.55066 0 6.68116 0.760545 5.30277 2.11432C3.92438 3.4681 3.15 5.30422 3.15 7.21875V17.9502C1.85719 19.5422 1.05 21.5273 1.05 23.7188C1.05 26.1803 2.04563 28.541 3.81784 30.2816C5.59006 32.0222 7.99371 33 10.5 33C13.0063 33 15.4099 32.0222 17.1822 30.2816C18.9544 28.541 19.95 26.1803 19.95 23.7188C19.95 21.5273 19.1428 19.5357 17.85 17.9502ZM10.5 28.875C9.3989 28.8767 8.32523 28.5377 7.43133 27.9063C6.53744 27.2748 5.86873 26.3829 5.5201 25.3571C5.17146 24.3313 5.16061 23.2237 5.48908 22.1915C5.81754 21.1593 6.46865 20.2549 7.35 19.6066V7.21875C7.35 6.39824 7.68188 5.61133 8.27262 5.03114C8.86336 4.45095 9.66457 4.125 10.5 4.125C11.3354 4.125 12.1366 4.45095 12.7274 5.03114C13.3181 5.61133 13.65 6.39824 13.65 7.21875V19.6066C14.5314 20.2549 15.1825 21.1593 15.5109 22.1915C15.8394 23.2237 15.8285 24.3313 15.4799 25.3571C15.1313 26.3829 14.4626 27.2748 13.5687 27.9063C12.6748 28.5377 11.6011 28.8767 10.5 28.875V28.875Z"/>
            <path fill="currentColor" d="M13.65 23.7188C13.6519 24.2864 13.4947 24.8435 13.1957 25.3293C12.8967 25.815 12.4674 26.2106 11.9548 26.4728C11.4422 26.7349 10.8661 26.8535 10.2895 26.8156C9.71283 26.7776 9.15794 26.5846 8.68552 26.2576C8.21311 25.9307 7.84139 25.4824 7.61104 24.9618C7.3807 24.4412 7.30061 23.8685 7.37954 23.3062C7.45848 22.7439 7.69339 22.2138 8.05858 21.7738C8.42376 21.3339 8.90513 21.0011 9.45 20.8119V9.28125C9.45 9.00775 9.56063 8.74544 9.75754 8.55205C9.95446 8.35865 10.2215 8.25 10.5 8.25C10.7785 8.25 11.0456 8.35865 11.2425 8.55205C11.4394 8.74544 11.55 9.00775 11.55 9.28125V20.8119C12.1632 21.0235 12.6945 21.4168 13.0706 21.9374C13.4467 22.458 13.6491 23.0804 13.65 23.7188Z"/>
        </g>
        <g id="cloud-sun">
            <path fill="currentColor" opacity=".4" d="M366.8 61.89L279 91.18 237.5 8.4a15.13 15.13 0 0 0-27.1 0L169 91.18 81.1 61.89a15.23 15.23 0 0 0-19.2 19.19l29.3 87.79-82.8 41.49a15.12 15.12 0 0 0 0 27.09l82.8 41.4-29.3 87.78a15.16 15.16 0 0 0 19.2 19.19l76.1-25.29A129 129 0 0 1 180.8 327a110.27 110.27 0 0 1-36-24 112 112 0 1 1 180.7-125.53 143.5 143.5 0 0 1 30.7-7.5L386 81.08a15.16 15.16 0 0 0-19.2-19.19zM224 140a83.85 83.85 0 0 0-16.1 166.17 128.29 128.29 0 0 1 25.2-11.7 144.12 144.12 0 0 1 67.4-105A84.15 84.15 0 0 0 224 140z" class="fa-secondary"></path>
            <path fill="currentColor" d="M575.2 325.64c37.6 13 64.8 48.29 64.8 90.38a96 96 0 0 1-96 96H272a96 96 0 0 1-96-96c0-50.59 39.3-91.58 88.9-95.28-.2-2.9-.9-5.7-.9-8.7a112 112 0 0 1 112-112c45.4 0 84.3 27.2 101.9 66A63.81 63.81 0 0 1 576 320a53.91 53.91 0 0 1-.8 5.6z" class="fa-primary"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Icons"
    }
</script>

<style scoped>

</style>