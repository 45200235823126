//config
module.exports={
    password:9515,
    unit:{
        type:'COMPACT',
        maxfan:800
    },
    debug: true,
    dist: true,
    modbus:{
        host:'178.67.142.9',
        //host:'10.1.5.10',
        port:'502'
    },
    ws:{
        host: 'mg.pmvent.ru',
        http_port: 5554,
        ws_port: 5555, // только для dist != true
        //connect_string: "ws://mg.pmvnt.ru",
        connect_string:"wss://mg.pmvent.ru/ws/",
        //connect_string:"ws://git.office.pmvent.ru:7777",
    },
    interval: 5000, //ms
};
