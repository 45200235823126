<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="103" viewBox="0 0 136 103" fill="none">
        <path d="M118.077 59.0856L70.2161 16.9863C69.6057 16.4497 68.8204 16.1537 68.0071 16.1537C67.1939 16.1537 66.4085 16.4497 65.7982 16.9863L17.9371 59.0892C17.278 59.6794 16.4114 59.9844 15.5274 59.9372C14.6433 59.8899 13.8142 59.4944 13.222 58.8373L8.75212 53.8839C8.45916 53.5588 8.23329 53.1793 8.08739 52.7669C7.9415 52.3546 7.87847 51.9176 7.9019 51.4809C7.92534 51.0443 8.03477 50.6165 8.22394 50.2221C8.41312 49.8277 8.67833 49.4744 9.00439 49.1824L62.4379 2.14145C63.9676 0.762923 65.955 -0.000152588 68.0156 -0.000152588C70.0763 -0.000152588 72.0637 0.762923 73.5934 2.14145L94.723 20.7431V9.98654C94.7231 9.54889 94.8096 9.11555 94.9776 8.71129C95.1455 8.30704 95.3915 7.93978 95.7017 7.63052C96.0118 7.32126 96.38 7.07606 96.7851 6.90893C97.1902 6.7418 97.6243 6.65603 98.0626 6.6565H111.419C111.858 6.65603 112.292 6.7418 112.697 6.90893C113.102 7.07606 113.47 7.32126 113.78 7.63052C114.091 7.93978 114.337 8.30704 114.505 8.71129C114.672 9.11555 114.759 9.54889 114.759 9.98654V38.3838L127.007 49.1717C127.333 49.4635 127.599 49.8167 127.788 50.2111C127.978 50.6054 128.087 51.0332 128.111 51.4699C128.135 51.9066 128.072 52.3437 127.927 52.7563C127.781 53.1688 127.556 53.5486 127.263 53.8741L122.796 58.8319C122.204 59.4889 121.375 59.8845 120.491 59.9317C119.607 59.9789 118.741 59.674 118.082 59.0838L118.077 59.0856Z" fill="#6C757D"/>
        <path d="M40.4205 102.953C40.2179 102.985 40.0132 103 39.8082 103H31.8415C31.6973 103 31.5531 102.992 31.4097 102.977L40.4205 102.953Z" fill="#CED4DA"/>
        <path d="M26.7612 79.5283C26.7588 79.7487 26.7119 79.9663 26.6232 80.168C26.5345 80.3698 26.4058 80.5516 26.245 80.7026L22.2222 84.4584L16.2072 90.0724C15.1451 91.0701 13.3802 90.3279 13.3802 88.8766V83.7673H1.43014C1.05219 83.7665 0.689597 83.6178 0.420166 83.3531C0.287751 83.2231 0.182447 83.0681 0.110335 82.8972C0.0382224 82.7262 0.000712865 82.5428 3.08049e-07 82.3573V76.6922C-0.000123096 76.5075 0.0368314 76.3246 0.108647 76.1544C0.180551 75.983 0.285826 75.8276 0.418369 75.6972C0.688226 75.4322 1.05163 75.2838 1.43014 75.284H13.382V69.6502C13.382 68.1829 15.1775 67.4487 16.2341 68.4849L22.2222 74.3607L26.2701 78.3334C26.4278 78.488 26.5527 78.6728 26.6373 78.8766C26.7231 79.083 26.7653 79.3049 26.7612 79.5283V79.5283Z" fill="#6C757D"/>
        <path d="M104.957 102.977C104.819 102.993 104.68 103 104.542 103H96.6739C96.4695 103 96.2653 102.984 96.0634 102.952L104.957 102.977Z" fill="#CED4DA"/>
        <path d="M116.974 93.2008C117.029 93.3319 117.054 93.4732 117.049 93.6149C117.043 93.7565 117.007 93.8952 116.943 94.0219C116.879 94.1483 116.789 94.2596 116.679 94.3482L112.702 97.5249L106.931 102.134C106.341 102.606 105.627 102.898 104.875 102.977C80.3585 102.91 55.8417 102.913 31.3249 102.977C30.5658 102.895 29.8463 102.597 29.252 102.118L23.1374 97.2937L19.3821 94.3356C19.2697 94.2476 19.1778 94.1363 19.1127 94.0093C19.048 93.8832 19.0115 93.7445 19.0059 93.6028C19.0004 93.4612 19.0258 93.32 19.0804 93.1892C19.135 93.0578 19.2174 92.9397 19.3219 92.8432L21.7889 90.5664C21.9665 90.404 22.196 90.3099 22.4366 90.3009C22.6772 90.2919 22.9131 90.3685 23.1024 90.5171L23.1356 90.5431L27.6773 94.1286V86.5094V64.3715L23.1356 67.9526L23.0458 68.0252C22.8523 68.1737 22.6123 68.2487 22.3685 68.2368C22.1248 68.2248 21.8932 68.1266 21.7153 67.9597L19.2986 65.6417C19.1981 65.5432 19.1195 65.4247 19.0679 65.2939C18.9909 65.0966 18.9791 64.8799 19.0341 64.6754C19.0892 64.471 19.2083 64.2894 19.374 64.1573L23.1374 61.1867V61.0971L29.9522 55.4929L66.1399 25.7332C66.6453 25.3264 67.275 25.1046 67.9242 25.1046C68.5734 25.1046 69.203 25.3264 69.7085 25.7332L105.915 55.4929L108.183 57.3565L108.201 57.3708L116.687 64.1528C116.797 64.2403 116.888 64.3503 116.952 64.4755C117.016 64.6009 117.053 64.7383 117.061 64.8789C117.067 65.0193 117.044 65.1595 116.992 65.2904C116.941 65.4212 116.862 65.5397 116.762 65.6381L114.374 67.9553C114.201 68.1254 113.972 68.2262 113.73 68.2385C113.487 68.2507 113.249 68.1735 113.06 68.0216L112.701 67.7356L108.482 64.368L108.488 94.1232L112.707 90.7672L113.008 90.5278C113.194 90.3781 113.427 90.3006 113.666 90.3097C113.904 90.3188 114.132 90.4138 114.305 90.5771L116.742 92.853C116.843 92.9513 116.922 93.0698 116.974 93.2008Z" fill="#CED4DA"/>
        <path d="M136 78.9933C136.002 79.2131 135.961 79.4312 135.878 79.6347C135.794 79.8382 135.671 80.0231 135.515 80.1783L125.603 90.0259C124.563 91.0657 122.786 90.3315 122.786 88.8606V83.2286H110.983C110.798 83.2291 110.615 83.1929 110.444 83.122C110.273 83.0516 110.117 82.9479 109.985 82.8168C109.854 82.6857 109.75 82.5298 109.68 82.3583C109.608 82.1879 109.572 82.0051 109.572 81.8204V76.1625C109.571 75.7874 109.719 75.4273 109.984 75.1612C110.249 74.8952 110.608 74.745 110.984 74.7435H122.789V69.6342C122.789 68.1829 124.531 67.4407 125.581 68.4384L135.494 77.8163C135.653 77.9691 135.779 78.1519 135.867 78.3541C135.954 78.5562 135.999 78.7735 136 78.9933Z" fill="#6C757D"/>
        <circle cx="68" cy="72" r="25" fill="white"/>
        <path :fill="color(1)" d="M60.9718 72.0023C60.9718 71.7105 60.9891 71.419 61.0236 71.1292C56.7159 69.7241 51.5421 61.5073 48.2497 66.5192C44.1782 72.7107 50.8003 84.4556 57.4049 85.3969C61.5934 85.994 65.2443 82.1039 67.3505 79.1771C65.5998 78.9911 63.9793 78.155 62.8013 76.8301C61.6234 75.5052 60.9717 73.7853 60.9718 72.0023V72.0023Z"/>
        <path :fill="color(2)" d="M82.5828 57.2173C79.3204 50.5484 65.9726 50.4875 61.8628 55.8101C59.2106 59.2469 60.8143 64.5201 62.2844 67.8267C62.827 67.0532 63.5149 66.3956 64.3087 65.8917C65.1026 65.3879 65.9868 65.0477 66.9107 64.8905C67.8346 64.7334 68.78 64.7624 69.6928 64.976C70.6056 65.1896 71.4678 65.5835 72.23 66.1351C75.5722 63.0436 85.2256 62.6217 82.5828 57.2173Z"/>
        <path :fill="color(3)" d="M84.9304 74.5156C83.3287 70.4964 78.0752 69.256 74.5155 68.8835C74.9282 69.7563 75.1634 70.7043 75.2072 71.671C75.251 72.6378 75.1024 73.6035 74.7703 74.511C74.4382 75.4184 73.9294 76.2489 73.2741 76.9531C72.6188 77.6574 71.8304 78.221 70.9558 78.6104C71.7928 83.1291 67.5028 91.6072 73.3738 91.9861C80.7014 92.4593 87.4282 80.7795 84.9304 74.5156Z"/>
        <path d="M68.0922 66.1362C66.9466 66.1362 65.8267 66.4803 64.8741 67.125C63.9216 67.7698 63.1791 68.6862 62.7408 69.7583C62.3024 70.8305 62.1877 72.0102 62.4113 73.1484C62.6349 74.2865 63.1867 75.332 63.9968 76.1525C64.807 76.973 65.8392 77.5317 66.9629 77.758C68.0865 77.9842 69.2512 77.8679 70.3096 77.4236C71.3679 76.9794 72.2725 76.2272 72.9089 75.2622C73.5452 74.2972 73.8847 73.1628 73.8845 72.0023C73.8845 71.2319 73.7347 70.469 73.4436 69.7572C73.1525 69.0454 72.7258 68.3987 72.1879 67.854C71.6501 67.3092 71.0115 66.8771 70.3088 66.5824C69.606 66.2877 68.8528 66.136 68.0922 66.1362V66.1362ZM68.0922 76.5252C67.2081 76.5252 66.3438 76.2597 65.6086 75.7621C64.8735 75.2645 64.3005 74.5573 63.9622 73.7299C63.6238 72.9025 63.5353 71.992 63.7078 71.1137C63.8803 70.2353 64.306 69.4285 64.9312 68.7952C65.5564 68.1619 66.353 67.7306 67.2201 67.5559C68.0873 67.3812 68.9861 67.4709 69.803 67.8136C70.6198 68.1563 71.318 68.7367 71.8092 69.4814C72.3004 70.226 72.5626 71.1015 72.5626 71.9971C72.5634 72.5922 72.4484 73.1816 72.2241 73.7317C71.9998 74.2817 71.6706 74.7816 71.2554 75.2027C70.8402 75.6237 70.3471 75.9577 69.8043 76.1856C69.2616 76.4134 68.6798 76.5306 68.0922 76.5305V76.5252Z" fill="#CED4DA"/>
        <path d="M68.0933 74.5157C69.4636 74.5157 70.5745 73.3904 70.5745 72.0023C70.5745 70.6143 69.4636 69.489 68.0933 69.489C66.7229 69.489 65.6121 70.6143 65.6121 72.0023C65.6121 73.3904 66.7229 74.5157 68.0933 74.5157Z" fill="#CED4DA"/>
    </svg>
</template>

<script>
    export default {
        name: "ImgHome",
        props:['fanlvl'],
        methods:{
            color(lvl){
                if (this.fanlvl >= lvl){
                    return '#6ABF4B';
                }
                return '#CED4DA';
            }
        }
    }
</script>

<style scoped>

</style>